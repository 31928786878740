/*
* Colors
*/

/* Primary */
:where(.primary) {
  background: var(--button-primary-base-bg-color);
  border-color: var(--button-primary-base-bg-color);
  color: var(--button-primary-base-bg-color);

  &:after {
    border-color: var(--button-primary-base-bg-color);
  }

  &:hover {
    background: var(--button-primary-hover-bg-color);
    border-color: var(--button-primary-hover-bg-color);
    color: var(--button-primary-hover-bg-color);

    &:after {
      border-color: var(--button-primary-hover-bg-color);
    }
  }

  &:active,
  &:focus-visible {
    background: var(--button-primary-active-bg-color);
    border-color: var(--button-primary-active-bg-color);
    color: var(--button-primary-active-bg-color);

    &:after {
      border-color: var(--button-primary-active-bg-color);
    }
  }

  &.darkMode {
    background: var(--color-championship-white);
    border-color: var(--color-championship-white);
    color: var(--color-primary-500);

    &:after {
      border-color: var(--color-primary-500);
    }

    &:hover {
      background: var(--color-primary-100);
      border-color: var(--color-primary-100);
      color: var(--color-primary-500);

      &:after {
        border-color: var(--color-primary-500);
      }
    }

    &:active,
    &:focus-visible {
      background: var(--color-primary-200);
      border-color: var(--color-primary-200);
      color: var(--color-primary-500);

      &:after {
        border-color: var(--color-primary-500);
      }
    }
  }
}

/* secondary: Only for Link variant */
:where(.link.secondary) {
  color: var(--color-neutral-700);

  &:hover {
    color: var(--color-neutral-600);
  }

  &:active,
  &:focus-visible {
    color: var(--color-neutral-700);
  }
}

/* tertiary */
:where(.tertiary) {
  background: var(--button-tertiary-base-bg-color);
  border-color: var(--button-tertiary-base-bg-color);
  color: var(--button-tertiary-base-bg-color);

  &:after {
    border-color: var(--button-tertiary-base-bg-color);
  }

  &:hover {
    background: var(--button-tertiary-hover-bg-color);
    border-color: var(--button-tertiary-hover-bg-color);
    color: var(--button-tertiary-hover-bg-color);

    &:after {
      border-color: var(--button-tertiary-hover-bg-color);
    }
  }

  &:active,
  &:focus-visible {
    background: var(--button-tertiary-active-bg-color);
    border-color: var(--button-tertiary-active-bg-color);
    color: var(--button-tertiary-active-bg-color);

    &:after {
      border-color: var(--button-tertiary-active-bg-color);
    }
  }
}

/* whatsapp */
:where(.whatsapp) {
  background: var(--button-whatsapp-base-bg-color);
  border-color: var(--button-whatsapp-base-bg-color);
  color: var(--button-whatsapp-base-bg-color);

  &:after {
    border-color: var(--button-whatsapp-base-bg-color);
  }

  &:hover {
    background: var(--button-whatsapp-hover-bg-color);
    border-color: var(--button-whatsapp-hover-bg-color);
    color: var(--button-whatsapp-hover-bg-color);

    &:after {
      border-color: var(--button-whatsapp-hover-bg-color);
    }
  }

  &:active,
  &:focus-visible {
    background: var(--button-whatsapp-active-bg-color);
    border-color: var(--button-whatsapp-active-bg-color);
    color: var(--button-whatsapp-active-bg-color);

    &:after {
      border-color: var(--button-whatsapp-active-bg-color);
    }
  }
}

:where(.mint) {
  background: var(--color-heycar-mint-100);
  border-color: var(--color-heycar-mint-100);
  color: var(--color-tarmac-grey-700);

  &:after {
    border-color: var(--color-heycar-mint-100);
  }

  &:hover {
    background: var(--color-heycar-mint-200);
    border-color: var(--color-heycar-mint-200);
    color: var(--color-tarmac-grey-700);

    &:after {
      border-color: var(--color-heycar-mint-200);
    }
  }

  &:active,
  &:focus-visible {
    background: var(--color-heycar-mint-200);
    border-color: var(--color-heycar-mint-200);
    color: var(--color-tarmac-grey-700);

    &:after {
      border-color: var(--color-heycar-mint-200);
    }
  }
}

/*
* Variants
*/

/* contained */
:where(.contained) {
  border-radius: var(--border-radius);

  &.primary {
    color: var(--button-primary-color);

    &.darkMode {
      color: var(--color-primary-500);
    }
  }

  &.tertiary {
    color: var(--button-tertiary-color);
  }

  &.whatsapp {
    color: var(--button-primary-color);
  }

  &:disabled,
  &[disabled] {
    background: var(--button-disabled-bg-color);
    border-color: var(--button-disabled-bg-color);
    color: var(--button-disabled-color);
  }

  &.darkMode {
    &:disabled,
    &[disabled] {
      background: var(--color-neutral-50);
      border-color: var(--color-neutral-50);
      color: var(--color-neutral-500);
      opacity: 0.3;
    }
  }
}

/* outlined */
:where(.outlined) {
  background: transparent;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: currentColor;
    box-sizing: border-box;
    z-index: 0;
    border-radius: var(--border-radius);
  }

  &:hover {
    background: transparent;

    &:after {
      border-width: 2px;
    }
  }

  &:active,
  &:focus-visible {
    background: none;
    &:after {
      border-width: 2px;
    }
  }

  &.tertiary {
    color: var(--button-tertiary-color);
  }

  &:disabled,
  &[disabled] {
    color: var(--button-disabled-color);
    background: none;

    &:after {
      border-color: var(--button-disabled-bg-color);
    }
  }

  &.darkMode {
    background: transparent;
    color: var(--color-championship-white);

    &:after {
      border-color: var(--color-championship-white);
    }

    &:hover {
      background: transparent;
      color: var(--color-primary-100);

      &:after {
        border-color: var(--color-primary-100);
      }
    }

    &:active,
    &:focus-visible {
      color: var(--color-primary-200);

      &:after {
        border-color: var(--color-primary-200);
      }
    }

    &:disabled,
    &[disabled] {
      color: var(--color-neutral-500);

      &:after {
        border-color: var(--color-neutral-500);
      }
    }
  }
}

:where(.link) {
  min-height: auto;
  padding: 0;
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 1px;

  &:hover {
    background: transparent;
    text-decoration-thickness: 2px;
  }

  &:active,
  &:focus-visible {
    background: transparent;
  }

  &:disabled,
  &[disabled] {
    border-color: var(--button-disabled-color);
    color: var(--button-disabled-color);
  }

  &.darkMode {
    background: transparent;
    color: var(--color-championship-white);

    &:after {
      border-color: var(--color-championship-white);
    }

    &:hover {
      background: transparent;
      color: var(--color-primary-100);
    }

    &:active,
    &:focus-visible {
      color: var(--color-primary-200);
    }

    &:disabled,
    &[disabled] {
      color: var(--color-neutral-500);
    }
  }
}
